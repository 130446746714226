@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.CMJ {
    position: relative;
    height: 100%;
    margin: 0 -100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .Report {
        margin-top: 620px;
        font-size: 20px;

        .Top {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            > div {
                margin-right: 8px;
                opacity: 0.618;
                display: flex;
                flex-direction: row;

                > div {
                    width: 140px;
                }
            }
        }
        .Bottom {
            display: flex;
            flex-direction: column;

            > div {
                display: flex;
                flex-direction: column;
                padding-top: 20px;

                > div {
                    display: flex;
                    flex-direction: row;
                    padding-top: 10px;

                    > div {
                        width: 140px;

                        > span {
                            padding-left: 5px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
