@import "./less/colors.less";
@import "./less/sizes.less";

html, body {
    zoom: 1;

    margin: 0px;
    padding: 0px;

    font-family: Helvetica, Arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
}

body {
    height: 100%;
    overflow-y: hidden;
}

h1 {
    font-size: @h1;
}

h2 {
    font-size: @h2;
}

h3 {
    font-size: @h3;
}

h5 {
    font-size: @h5;
}

p {
    font-size: @p;
}

* {
    box-sizing: border-box;
}

canvas {
    vertical-align: top;
}

:global(#root) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
